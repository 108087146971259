import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";
import "./Project.css";

import {
  FilePdfOutlined,
  FileOutlined,
  InfoCircleFilled,
  MergeCellsOutlined,
  PlusOutlined,
  LeftOutlined,
  EditOutlined,
  HomeFilled
} from "@ant-design/icons";


import { DatePicker } from "antd";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getParsedDate } from "../../components/globalFunctions";

import { useTranslate } from "../../components/Translate";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import moment, { isMoment } from "moment";


import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import { getEnvSettings } from "../../config/config";
import { ProjectElements } from "./ProjectElements";
import { BackBtn } from "../../components/BackBtn/BackBtn";
import ReactDOM from 'react-dom';
import { ProjectDroppableEtaps } from "./ProjectDroppableEtaps/ProjectDroppableEtaps";
import { ProjectDroppableDeliverys } from "./ProjectDroppableDeliverys/ProjectDroppableDeliverys";



export const ProjectElementsRoot = ({ }) => {
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();
  let { id } = useParams();
  const navigate = useNavigate();

  const [projectRootData, updateProjLocalData, setAnimationRoot] = useOutletContext();

  // console.log('useOutletContext projectData', projectData)

  const [isLoading, setisLoading] = useState(true);


  const [projectData, setProjectData] = useState([]);
  const [animation, setAnimation] = useState("");
  const [useDragging, setUseDragging] = useState(false);

  const [draggingType, setDraggingType] = useState(null);


  const [enableEditing, setEnableEditing] = useState(false);

  const [listType, setListType] = useState("elementit");
  const [itemsInDroppable, setItemsInDroppable] = useState({});

  // Ref-komponentit
  const etapsRef = useRef(null);
  const deliverysRef = useRef(null);


  useEffect(() => {
    setAnimationRoot(animation)
  }, [animation]);


  const getProject = () => {
    setisLoading(true);
    console.log("getProject");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getProject`,
      data: {
        projectId: id,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        setProjectData(response.data.data);

        setTimeout(() => {
          setisLoading(false);
        }, 250);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getProject()
  }, [id]);




  /*   useEffect(() => {
      console.log("ID muuttui:", id);
  
      // Nollataan kaikki tilat
      setAnimation("");
      setUseDragging(false);
      setEnableEditing(false);
  
      setListType("elementit");
      setItemsInDroppable({});
  
  
    }, [id]); // Tämä suoritetaan aina, kun id muuttuu
   */


  useEffect(() => {
    console.log('Updated itemsInDroppable 1:', itemsInDroppable);
  }, [itemsInDroppable]); // Tämä varmistaa, että muutokset näkyvät



  const handleShowDragging = async (type) => {
    console.log('handleShowDragging', type, draggingType, useDragging)

    if (useDragging && type == draggingType) {
      setDraggingType(null)
      setAnimation("closing");
      await new Promise((r) => setTimeout(r, 500)); // 0.5s delay
      setAnimation("closed");
      setUseDragging(false);

    } else {
      setAnimation("open");
      setDraggingType(type)
      setUseDragging(true);
    }
  };

  const onBeforeCapture = (result) => {
    console.log("onBeforeCapture");
    console.log("==> beforeresult", result);
    const id = result.draggableId;

    if (id.includes("dropped")) return;

    const cardSize = 140;
    const draggableAttr = "data-rbd-drag-handle-draggable-id";
    const getAttr = (key, value) => `[${key}="${value}"]`;
    const draggableQuery = getAttr(draggableAttr, id);
    //console.log("draggableQuery:", draggableQuery);
    const draggable = document.querySelector(draggableQuery);
    // console.log("DRAGGABLE:", draggable);

    //console.log('coords22', draggable.getBoundingClientRect())
    draggable.setAttribute("style", `width: ${cardSize}px;`);
  };


  // Käsittele drag end
  const onDragEnd = (result) => {
    if (etapsRef.current) {
      etapsRef.current.handleDragEnd(result);  // Kutsu Etaps-komponentin funktiota ref:in kautta)
    }

    /*     if (listType === "etapit" && etapsRef.current) {
      etapsRef.current.handleDragEnd(result);  // Kutsu Etaps-komponentin funktiota ref:in kautta
    } else if (listType === "kuormat" && deliverysRef.current) {
      deliverysRef.current.handleDragEnd(result);  // Kutsu Kuormat-komponentin funktiota ref:in kautta
    } */
  }


  if (isLoading) {
    return (
      <div className="project-loading-view">
        <LoadingItem color="#000" height={'3vh'} width={'3vh'} />
      </div>)
  }

  if (!projectData.loaded) {
    return (
      <div className="projects-nodata">
        <p className="projects-nodata-text font-dm-bold">Elementtitietoja ei saatu!</p>
      </div>
    );
  }

  return (<>

    <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>

      <ProjectElements navigate={navigate} projectId={id} projectRootData={projectRootData} projectData={projectData} animation={animation} listType={listType} setListType={setListType} enableEditing={enableEditing} itemsInDroppable={itemsInDroppable} useDragging={useDragging} handleShowDragging={handleShowDragging} draggingType={draggingType} />

      {/* Portaali, joka nostaa MyComponentin target-div:in alle */}
      {document.getElementById('projectmain') && ReactDOM.createPortal(
        <>
          {/* DRAGGING */}
          {useDragging && (
            <div className={`droppablecontainer ${animation}`}>
              {draggingType === 'etap' ?
                <ProjectDroppableEtaps ref={etapsRef} id={id} projectData={projectData} setItemsInDroppable={setItemsInDroppable} enableEditing={enableEditing} setEnableEditing={setEnableEditing} getProject={getProject} />
                : <></>}

              {/* Kuormat tähän..*/}
              {draggingType === 'delivery' ?
                <ProjectDroppableDeliverys ref={etapsRef} id={id} projectData={projectData} setItemsInDroppable={setItemsInDroppable} enableEditing={enableEditing} setEnableEditing={setEnableEditing} getProject={getProject} />
                : <></>}
            </div>
          )}
        </>,
        document.getElementById('projectmain')
      )}

    </DragDropContext>

  </>);
};
