import { useEffect, useRef, useState } from "react";
import { apiSecure } from "../../../components/api";
import { LoadingItem } from "../../../components/Loading/Loading";

import { useTranslate } from "../../../components/Translate";

import { getEnvSettings } from "../../../config/config";


import { getParsedDate } from "../../../components/globalFunctions";
import { Input, Modal, notification, Tooltip } from "antd";
import { RichEditor } from "../../../components/RichEditor/RichEditor";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectNoteFiles, ProjectNoteHeader, ProjectNoteParticipants, ProjectNoteTasks } from "./ProjectNoteComponents";





export const ProjectNote = ({ }) => {
  const { locale } = useTranslate();
  let { id, noteId } = useParams();
  const navigate = useNavigate();

  const envConfig = getEnvSettings();
  const [isLoading, setisLoading] = useState(true);

  const [note, setNote] = useState({});

  const maxNoteSize = 5 * 1024 * 1024; // 2 MB
  const [noteSize, setNoteSize] = useState(0); // Kokonausmuistion koko

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(true);

  const richEditorRef = useRef(null);

  //Osallistujat
  const [showParticipants, setShowParticipants] = useState(false);
  const [participantsText, setParticipantsText] = useState("");

  //Toimenpiteet
  const [showTasks, setShowTasks] = useState(false);

  //Liitteet
  const [showFiles, setShowFiles] = useState(false);


  //NoteInitial
  useEffect(() => {
    if (note?.Osallistujat && note?.Osallistujat?.length > 0) {
      setShowParticipants(true)
      setParticipantsText(note.Osallistujat)
    }
  }, [note]);

  useEffect(() => {
    if (noteSize >= maxNoteSize) {
      notification.open({
        message: locale.note_size_error,
        placement: 'top',
        type: 'error'
      });
    }
  }, [noteSize]);


  const saveNoteManual = async () => {
    if (richEditorRef.current) {
      try {
        await richEditorRef.current.saveNote();
      } catch (error) {
        console.log("Tallennus epäonnistui:", error);
      }
    }
  };

  const closeNoteFunc = () => {
    navigate(-1)
  }


  const getNote = () => {
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getProjectNote`,
      data: {
        projectId: id,
        noteId: noteId,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        setNote(response.data.data)
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    console.log('getNote')
    getNote()
  }, []);


  const canDeleteNote = async () => {
    console.log('canDeleteNote')

    Modal.confirm({
      title: locale.note_delete_question,
      okText: locale.yes,
      cancelText: locale.no,
      onOk: deleteNote
    });
  }

  const deleteNote = async () => {
    console.log('deleteNote')

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}deleteNote`,
      data: {
        noteId: noteId,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        if (response?.data?.status) {
          notification.open({
            message: locale.note_deleted,
            placement: 'top',
            type: 'success'
          });
          navigate(-1)
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateNoteTitle = (title) => {
    console.log('updateNoteTitle', title)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectNote`,
      data: {
        noteId: noteId,
        type: "title",
        value: title
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        if (response?.data?.status) {
          notification.open({
            message: locale.note_header_updated,
            placement: 'top',
            type: 'success'
          });
        } else {
          notification.open({
            message: locale.note_header_update_failed,
            placement: 'top',
            type: 'error'
          });
        }

      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };


  const updateNoteParticipants = (partText) => {
    console.log('updateNoteParticipants', partText)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectNote`,
      data: {
        noteId: noteId,
        type: "participants",
        value: partText
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        if (response?.data?.status) {
          notification.open({
            message: locale.note_participants_updated,
            placement: 'top',
            type: 'success'
          });
        } else {
          notification.open({
            message: locale.note_participants_update_failed,
            placement: 'top',
            type: 'error'
          });
        }

      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateNoteData = async (jsonData, isAuto) => {
    console.log('isAuto', isAuto)
    if (noteSize >= maxNoteSize) {
      notification.open({
        message: locale.note_size_error2,
        placement: 'top',
        type: 'error'
      });
      throw new Error(locale.note_size_error2); // Heitä virhe, jos muistiinpano on liian suuri
    }

    setSaving(true)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectNote`,
      data: {
        noteId: noteId,
        type: "data",
        value: jsonData
      },
    };

    console.log("UPP2", req_config);

    try {
      const response = await apiSecure(req_config);

      if (response?.data?.status) {
        setSaved(true)

        if (!isAuto) {
          notification.open({
            message: `${locale.note_updated}`,
            placement: 'top',
            type: 'success'
          });
        }

      } else {
        notification.open({
          message: `${locale.note_update_failed}`,
          placement: 'top',
          type: 'error'
        });
        throw new Error(`${locale.note_update_failed}`);
      }
    } catch (error) {
      console.log("Error:", error);
      notification.open({
        message: locale.note_save_failed,
        description: error.message,
        placement: 'top',
        type: 'error'
      });
      throw error; // Heitä virhe eteenpäin käsiteltäväksi muissa funktioissa
    } finally {
      setSaving(false)
    }
  };


  return (<div className={`projectpage-content`}>
    <div className="project-info">


      {note?.MuistioNro ?
        <ProjectNoteHeader
          note={note}
          noteSize={noteSize}
          maxNoteSize={maxNoteSize}
          closeNote={closeNoteFunc}
          saveNoteManual={saveNoteManual}
          updateNoteTitle={updateNoteTitle}
          saved={saved}
          saving={saving}
          showParticipants={showParticipants}
          setShowParticipants={setShowParticipants}
          participantsText={participantsText}
          canDeleteNote={canDeleteNote}
          showTasks={showTasks}
          setShowTasks={setShowTasks}
          showFiles={showFiles}
          setShowFiles={setShowFiles}
        />
        : <></>}

      <div className="project-note">
        {!isLoading ? <>

          {note?.MuistioNro ? <>


            <ProjectNoteParticipants
              note={note}
              participantsText={participantsText}
              setParticipantsText={setParticipantsText}
              showParticipants={showParticipants}
              setShowParticipants={setShowParticipants}
              updateNoteParticipants={updateNoteParticipants}
            />

            <RichEditor
              ref={richEditorRef}
              note={note}
              noteSize={noteSize}
              setNoteSize={setNoteSize}
              maxNoteSize={maxNoteSize}
              updateNoteData={updateNoteData}
              setSaved={setSaved}
              saved={saved}
            />


            <ProjectNoteFiles
              noteId={noteId}
              showFiles={showFiles}
              setShowFiles={setShowFiles}
            />

            <ProjectNoteTasks
              noteId={noteId}
              workers={note?.workers}
              initialTasks={note?.tasks}
              showTasks={showTasks}
              setShowTasks={setShowTasks}
            />
          </>
            : <p className="font-dm-medium">Muistiota ei löydy</p>}

        </> :
          <div className="project-info-loading">
            <LoadingItem color="#000" height={'2vh'} width={'2vh'} />
          </div>
        }
      </div>

    </div>
  </div>

  );
};
