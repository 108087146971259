import "./RichEditor.css";

import ListItem from '@tiptap/extension-list-item'
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'

import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'

import { BoldOutlined, ItalicOutlined, StrikethroughOutlined, UnderlineOutlined, DownOutlined, UnorderedListOutlined, OrderedListOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import CustomImageExt from "./CustomImageExt";

const headerItems = [
  {
    key: '1',
    label: 'H1',
  },
  {
    key: '2',
    label: 'H2',
  },
  {
    key: '3',
    label: 'H3',
  },
]

export const RichEditor = forwardRef(({ note, noteSize, setNoteSize,maxNoteSize, updateNoteData, saved, setSaved }, ref) => {
  const saveTimeout = useRef(null);

  //Main editor
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3],
        },
      }),
      TextStyle,
      Color,
      CustomImageExt,
      Underline,
    ],
    content: `
    <p>Muistion sisältö</p>
  `,
    onUpdate({ editor }) {
      calcSize()
      setSaved(false)
      triggerSave(); // Kutsutaan triggerSave aina kun editorin sisältö päivittyy
    },
  })

  //Data Initial
  useEffect(() => {
    console.log('NoteData päiviteetty')
    if (note?.Sisalto) {
      //console.log('NoteData Sisalto', note.Sisalto)
      editor.commands.setContent(JSON.parse(note.Sisalto))
      // Set the cursor to the first position
      editor.commands.focus('start')
      calcSize()
    }
  }, [note]);

  //Ref palautus ProjectNote.js
  useImperativeHandle(ref, () => ({
    saveNote: saveNoteData
  }));

  // Tallennustoiminto
  const saveNoteData = async (isAuto=false) => {
    console.log('saveNoteData', noteSize)
    if (editor) {
      const jsonContent = editor.getJSON();
      console.log('Saving JSON content:', jsonContent);
      try {
        await updateNoteData(JSON.stringify(jsonContent), isAuto);
        console.log('Data saved successfully');
        setSaved(true);
      } catch (error) {
        console.error('Error saving note:', error);
        throw error;
      }
    } 

  }

  //Save odotus handler
  const triggerSave = useCallback(() => {
    console.log('TriggerSave')

    if (saveTimeout.current) {
      clearTimeout(saveTimeout.current);
    }

    saveTimeout.current = setTimeout(() => {
      saveNoteData(true);
    }, 5000); // Viivästytetään tallennusta 4 sekuntia
  }, [saveNoteData]);

/*   useEffect(() => {
    return () => {
      if (saveTimeout.current) {
        clearTimeout(saveTimeout.current);
      }
    };
  }, []); */


  //Varoitusviesti kun koitetaan poistua kokonaan
  useEffect(() => {
    const handleBeforeUnload = (event) => {


        console.log('handleBeforeUnload asdasd')

      if (!saved) {
        const confirmationMessage = "Sinulla on tallentamattomia muutoksia. Haluatko varmasti poistua?";
        event.returnValue = confirmationMessage; // Tämä on välttämätön, jotta selain näyttää varoituksen
        return confirmationMessage; // Joillain selaimilla tämä on myös tarpeen
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [saved]);



  //Lasketaan koko
  const calcSize = useCallback( () => {
    const jsonContent = editor.getJSON();
    if (jsonContent) {
      const jsonString = JSON.stringify(jsonContent)
      const bytes = new Blob([jsonString]).size;
      setNoteSize(bytes)
    }
  }, [editor])


  const onClickHeaders = ({ key }) => {
    console.log(`Click on item ${key}`);
    editor.chain().focus().toggleHeading({ level: parseInt(key) }).run()
  };

  return (
    <div className="editor-container">
      <EditorContent editor={editor} className="editor-content" />

      {/* Bubble Menu */}
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }} className="bubble-menu show">
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
          >
            <BoldOutlined />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          >
            <ItalicOutlined />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'is-active' : ''}
          >
            <StrikethroughOutlined />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive('underline') ? 'is-active' : ''}
          >
            <UnderlineOutlined />
          </button>

          {/* List options */}
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive("bulletList") ? "is-active" : ""}
          >
            <UnorderedListOutlined />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive("orderedList") ? "is-active" : ""}
          >
            <OrderedListOutlined />
          </button>


          {/* Ant Design Dropdown Menu for Headers */}
          <Dropdown menu={{ items: headerItems, onClick: onClickHeaders }}
            trigger={['click']}>
            <button className="ant-dropdown-link">
              Headers <DownOutlined />
            </button>
          </Dropdown>

        </BubbleMenu>
      )}
    </div>
  )
});