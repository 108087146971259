

import Icon from "@ant-design/icons";




const BackSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.25012 16.498L2.25011 13.498L5.25012 10.498" stroke="#A0A0A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" />
    <path d="M3 13.501L12.5 13.5962" stroke="#A0A0A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" />
  </svg>

);

const BackcIcon = (props) => <Icon component={BackSvg} {...props} />;


export function BackBtn({style,  onClick }) {

  return (
    <>
      <div
        className="backbtn clickable font-dm-regular"
        style={style}
        onClick={onClick}
      >
        <BackcIcon className="backbtnicon" /> <p className="backbtntxt">Takaisin</p>

      </div>
    </>
  );
}
