import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { apiSecure } from "../../../components/api";
import { Loading, LoadingItem } from "../../../components/Loading/Loading";

import {
  MergeCellsOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";


import { DatePicker } from "antd";
import { getParsedDate } from "../../../components/globalFunctions";

import { useTranslate } from "../../../components/Translate";

import { Draggable, Droppable } from "react-beautiful-dnd";

import moment, { isMoment } from "moment";


import { NewStage } from "./NewStage";
import { copy, move, reorder } from "../ProjectDraggable";
import { EditStageData } from "./EditStageData";
import { VerifyDelete } from "../VerifyDelete";
import dayjs from "dayjs";
import { getEnvSettings } from "../../../config/config";



export const ProjectDroppableEtaps = forwardRef(({ id, projectData, setItemsInDroppable, enableEditing, setEnableEditing, getProject }, ref) => {
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();

  //const [enableEditing, setEnableEditing] = useState(false);
  const [copiedList, setCopiedList] = useState({});
  const [droppableLists, setDroppableLists] = useState({});

  const [stageDataOpen, setStageDataOpen] = useState(false);
  const [stageData, setStageData] = useState({});

  const [newStageOpen, setNewStageOpen] = useState(false);

  const [verifyDeleteOpen, setVerifyDeleteOpen] = useState(false);
  const [verifyDeleteData, setVerifyDeleteData] = useState({});


  useEffect(() => {
    console.log('Updated droppableLists 2:', droppableLists);
  }, [droppableLists]); // Tämä varmistaa, että muutokset näkyvät


  //Initial etappien luonti
  useEffect(() => {
    if (projectData.etapit) {
      let newData = {};

      projectData.etapit.map((item, index) => {
        let isParent = item.ParentID === 0 ? true : false;

        newData["LIST_" + item.EtappiNro] = {
          title: item.Etappi,
          parentlist: isParent ? item.EtappiNro : item.ParentID,
          items: [],
          parent: isParent,
          startdate: item.ArvioituToimituksenAlku,
          enddate: item.ArvioituToimituksenLoppu,
        };
      });

      projectData.elementtiryhmat.map((group, index) => {
        group.data &&
          group.data.map((item, index2) => {
            if (!item.Etappi) return;
            //console.log("FOUND ETAPPI", item);
            if (newData["LIST_" + item.Etappi]) {
              newData["LIST_" + item.Etappi].items.push({
                id: item.ValmistusNro + "_dropped",
                date: item.ArvioituToimitusPvm,
                order: item.JarjestysEtapissa,
              });
            }
          });
      });

      //Laitetaan etapit järjestykseen
      Object.keys(newData).map((list, index) => {
        let items = newData[list].items;

        items.sort(function (a, b) {
          return a.order - b.order;
        });
      });

      console.log('setDroppableLists(newData);', newData)
      setDroppableLists(newData);
    }
  }, [projectData]);


// Päivitetään tieto elementeistä, jotka ovat etapeissa
useEffect(() => {
  if (Object.keys(droppableLists).length === 0) return;

  const updatedItemsInDroppable = Object.keys(droppableLists).reduce((acc, listKey) => {
    droppableLists[listKey].items.forEach((item) => {
      const itemId = parseInt(item.id.replace("_dropped", ""), 10);
      acc[itemId] = true;
    });
    return acc;
  }, {});

  console.log("itemsInDroppable", updatedItemsInDroppable);
  setItemsInDroppable(updatedItemsInDroppable); // Päivitetään state
}, [droppableLists]);


  const handleDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    console.log("handleDragEnd ==> result", result);

    // dropped outside the list
    if (!destination) return;

    switch (true) {
      case source.droppableId === destination.droppableId: //Sijainnin vaihto listassa
        setDroppableLists({
          ...droppableLists,
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: reorder(
              droppableLists[source.droppableId].items,
              source.index,
              destination.index
            ),
          },
        });
        break;

      case source.droppableId.includes("GROUP"): //elementtilistasta tauluun siirto
        let isInArray = isDraggableInAnyList(result.draggableId);
        if (isInArray) break;

        setDroppableLists({
          ...droppableLists,
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: copy(
              null,
              draggableId + "_dropped",
              droppableLists[destination.droppableId].items,
              destination
            ),
          },
        });
        break;

      case source.droppableId.includes("LIST"): //Taulusta toiseen siirto
        setDroppableLists({
          ...returnRemoveDraggableArray(source.droppableId, draggableId),
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: copy(
              source.droppableId,
              draggableId,
              droppableLists[destination.droppableId].items,
              destination
            ),
          },
        });
        break;

      default: //Jos muuta ei ole
        //console.log("CASE 4");

        if (source.droppableId.includes("GROUP")) return;
        return;
        setDroppableLists(
          move(
            droppableLists[source.droppableId],
            droppableLists[destination.droppableId],
            source,
            destination
          )
        );
        break;
    }
  };

  // expose the functions to parent component via ref
  useImperativeHandle(ref, () => ({
    handleDragEnd
  }));

  const onBeforeCapture = (result) => {
    console.log("onBeforeCapture");
    //console.log("==> beforeresult", result);


    const id = result.draggableId;

    if (id.includes("dropped")) return;

    const cardSize = 140;
    const draggableAttr = "data-rbd-drag-handle-draggable-id";
    const getAttr = (key, value) => `[${key}="${value}"]`;
    const draggableQuery = getAttr(draggableAttr, id);
    //console.log("draggableQuery:", draggableQuery);
    const draggable = document.querySelector(draggableQuery);
    // console.log("DRAGGABLE:", draggable);

    //console.log('coords22', draggable.getBoundingClientRect())
    draggable.setAttribute("style", `width: ${cardSize}px;`);
  };




  const getRowData = (id) => {
    if (!projectData.elementtiryhmat) return;
  
    for (const group of projectData.elementtiryhmat) {
      if (!group.data) continue;
  
      const foundItem = group.data.find((element) => element.ValmistusNro === id);
  
      if (foundItem) {
        return foundItem;
      }
    }
  
    return {}; // Palauttaa tyhjän objektin, jos ei löydy mitään
  };

  const handleOpenEtappiData = (id, name, start, end, parent) => {
    if (enableEditing) return;
    console.log("handleOpenEtappiDate", id);
    setStageData({
      id,
      name,
      start,
      end,
      parent
    });
    setStageDataOpen(true);
  };


  const returnRemoveDraggableArray = (list_id, draggable_id) => {
    return {
      ...droppableLists,
      [list_id]: {
        ...droppableLists[list_id],
        items: droppableLists[list_id].items.filter(
          (item) => item.id !== draggable_id
        ),
      },
    };
  };

  const removeDraggable = (list_id, draggable_id) => {
    setDroppableLists(returnRemoveDraggableArray(list_id, draggable_id));
  };

  const isDraggableInAnyList = (draggable_id) => {
    return Object.keys(droppableLists).some((list) =>
      droppableLists[list].items.some((x) => x.id === draggable_id + "_dropped")
    );
  };
  //////
  //////  DRAGGING END
  //////




  const updateStageDatas = (stage, name, start, end) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateStageDatas`,
      data: {
        projectId: id,
        stage: stage,
        name: name,
        start: start,
        end: end,
      },
    };
    // console.log(req_config);

    apiSecure(req_config)
      .then((response) => {
        //      console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };


  const deleteStage = (stage) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}deleteStage`,
      data: {
        projectId: id,
        stage: stage,
      },
    };

    //console.log("deletestage", req_config);

    apiSecure(req_config)
      .then((response) => {
        //  console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateStages = () => {
    // console.log("updateStages start", droppableLists);

    let newList = [];

    Object.keys(droppableLists).map((list, index) => {
      let listId = parseInt(list.replace("LIST_", ""));

      droppableLists[list].items.length >= 1 &&
        droppableLists[list].items.map((item, index2) => {
          console.log("ITEM", item);
          let itemId = parseInt(item.id.replace("_dropped", ""));
          newList.push({
            elementid: itemId,
            stage: listId,
            date: item.date,
            order: index2 + 1,
          });
        });
    });

    // console.log("updateStages send LIST", newList);

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateStages`,
      data: {
        projectId: id,
        list: newList,
      },
    };
    console.log('req_config', req_config)
    apiSecure(req_config)
      .then((response) => {
        // console.log("RESPONSE", response.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const addNewStage = (stage, name, start, end) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}newStage`,
      data: {
        projectId: id,
        stage: stage,
        name: name,
        start: start,
        end: end,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        //   console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };





  return (<>

    <EditStageData
      isOpen={stageDataOpen}
      closeModal={() => {
        setStageDataOpen(false);
      }}
      data={stageData}
      saveFunc={updateStageDatas}
      deleteStage={deleteStage}
      setVerifyDeleteData={setVerifyDeleteData}
      setVerifyDeleteOpen={setVerifyDeleteOpen}
    />

    <NewStage
      isOpen={newStageOpen}
      closeModal={() => {
        setNewStageOpen(false);
      }}
      saveFunc={addNewStage}
      stages={projectData.etapit}
    />

    <VerifyDelete
      isOpen={verifyDeleteOpen}
      closeModal={() => {
        setVerifyDeleteOpen(false);
      }}
      data={verifyDeleteData}
    />


    {/* Jos yhtään etappia ei ole, näytetään placeholder josta voi lisätä etapin */}
    {projectData.etapit.length <= 0 ?
      <>
        <div className={`droppablecontainer-placeholder`}>
          <div className={`droppablegroup-placeholder`}>
            <div className={`droppablebox parent`}  >
              <div className={`droppablebox-header`}>
                <div>
                  <p className={`droppablebox-placeholder-header-title font-dm-bold`}>
                    {locale.etaps}
                  </p>

                  <p className={`droppablebox-placeholder-text font-dm-regular`}>
                    {locale.etapintroduction}
                  </p>


                  <button
                    className="buttonstyle font-dm-regular"
                    style={{ marginLeft: 0, marginTop: "1.5em" }}
                    onClick={() => {
                      setNewStageOpen(true);
                    }}
                  >
                    <PlusOutlined style={{ fontSize: 15, marginRight: -5 }} />
                    {locale.etapadd}
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      : <>

        <div className={`droppablecontainerFixed`}>
          <div className={`droppablecontainerInner`}>
            {projectData.etapit.map((etappiItem, indx) => {
              if (etappiItem.ParentID != 0) return;

              console.log('56_test', etappiItem, droppableLists)

              return (
                <>
                  <div className={`droppablegroup ${enableEditing}`}>
                    {Object.keys(droppableLists).map((list, i) => {
                      const listdata = droppableLists[list];
                      console.log('562_test', list, listdata)

                      if (etappiItem.EtappiNro != listdata.parentlist) return;




                      return (
                        <Droppable
                          key={list}
                          droppableId={list}
                          isDropDisabled={!enableEditing}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                className={`droppablebox ${listdata.parent ? "parent" : ""
                                  }`}
                              >
                                <div className={`droppablebox-header`}>
                                  <div>
                                    <p className={`droppablebox-header-title font-dm-bold`}>
                                      {listdata.title}
                                    </p>

                                    <p className={`droppablebox-header-date font-dm-bold`}>
                                      {(listdata.startdate ||
                                        listdata.enddate) &&

                                        (listdata.startdate
                                          ? getParsedDate(
                                            listdata.startdate
                                          )
                                          : "") +
                                        " - " +
                                        (listdata.enddate
                                          ? getParsedDate(
                                            listdata.enddate
                                          )
                                          : "")}
                                    </p>
                                  </div>

                                  {!enableEditing && (
                                    <div className="droppablebox-edit">
                                      <EditOutlined
                                        key={list}
                                        id={list}
                                        className="droppablegroup-icon"
                                        onClick={() => {
                                          let itemId = parseInt(
                                            list.replace("LIST_", "")
                                          );

                                          handleOpenEtappiData(
                                            itemId,
                                            listdata.title,
                                            listdata.startdate,
                                            listdata.enddate,
                                            listdata.parent
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>

                                <div
                                  ref={provided.innerRef}
                                  className="droppableBack"
                                  style={{
                                    backgroundColor:
                                      snapshot.isDraggingOver
                                        ? "#244cdb3c"
                                        : "white",
                                  }}
                                >
                                  <div className="droppableInside">
                                    {droppableLists[list].items.length >=
                                      1
                                      ? droppableLists[list].items.map(
                                        (item, index) => {
                                          let itemId = parseInt(
                                            item.id.replace(
                                              "_dropped",
                                              ""
                                            )
                                          );

                                          let rowdata =
                                            getRowData(itemId);

                                          /* console.log("DATA:", rowdata, itemId); */

                                          let elementDate = droppableLists[list].items[index].date
                                          let isDateBetween = moment(elementDate).isBetween(moment(listdata.startdate), moment(listdata.enddate), 'days', true);

                                          return (
                                            <Draggable
                                              key={item.id}
                                              draggableId={item.id}
                                              index={index}
                                              isDragDisabled={
                                                !enableEditing
                                              }
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  className="draggableItem"
                                                  ref={
                                                    provided.innerRef
                                                  }
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={
                                                    provided
                                                      .draggableProps
                                                      .style
                                                  }
                                                >
                                                  <p
                                                    ro
                                                    className="font-dm-regular draggableItemText">
                                                    {rowdata.Tunnus}

                                                  </p>

                                                  <p className="font-dm-regular draggableItemText2">   {
                                                    rowdata.ValmistusNro
                                                  }</p>


                                                  <DatePicker
                                                    getPopupContainer={(
                                                      trigger
                                                    ) =>
                                                      trigger.parentElement
                                                    }
                                                    placeholder={""}
                                                    format={'DD.MM.YYYY'}
                                                    showWeek={true}
                                                    className={`draggableDatePicker font-dm-regular ${elementDate && !isDateBetween ? 'red' : ''}`}
                                                    size="small"
                                                    value={
                                                      elementDate ? dayjs(elementDate) : null
                                                    }
                                                    disabled={
                                                      !enableEditing
                                                    }
                                                    onChange={(
                                                      date,
                                                      dateString
                                                    ) => {
                                                      let saveDate = dayjs(date).format("YYYY-MM-DD") //Tietokantaa varten tähän tmalliin
                                                      droppableLists[
                                                        list
                                                      ].items[
                                                        index
                                                      ].date = saveDate;

                                                      setDroppableLists(
                                                        {
                                                          ...droppableLists,
                                                        }
                                                      );
                                                    }}
                                                  />

                                                  {enableEditing && (
                                                    <PlusOutlined
                                                      style={{
                                                        fontSize: 20,
                                                      }}
                                                      className="draggableItemClose"
                                                      onClick={() => {
                                                        removeDraggable(
                                                          list,
                                                          item.id
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              )}
                                            </Draggable>
                                          );
                                        }
                                      )
                                      : null}
                                    {provided.placeholder}
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        </Droppable>
                      );
                    })}
                  </div>
                </>
              );
            })}
            <></>
          </div>

          {!enableEditing && (
            <div className="etappiBtns">
              <button
                className="buttonstyle font-dm-regular"
                onClick={() => {
                  setNewStageOpen(true);
                }}
              >
                <PlusOutlined style={{ fontSize: 15, marginRight: -5 }} />
                {locale.etapadd}
              </button>

              <button
                className="buttonstyle font-dm-regular"
                onClick={() => {
                  setCopiedList(
                    JSON.parse(JSON.stringify(droppableLists))
                  );
                  setEnableEditing(!enableEditing);
                }}
              >
                <MergeCellsOutlined
                  style={{ fontSize: 15, marginRight: -5 }}
                />
                {locale.etapcontrol2}
              </button>
            </div>
          )}

          {enableEditing && (
            <div className="etappiBtns">
              <button
                className="buttonstyle font-dm-regular"
                style={{
                  backgroundColor: "#25D155",
                  width: "150px",
                }}
                onClick={() => {
                  setCopiedList(droppableLists);
                  setEnableEditing(!enableEditing);
                  updateStages();
                  getProject();
                }}
              >
                {locale.save}
              </button>

              <button
                className="buttonstyle font-dm-regular"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid grey",
                  color: "#575757",
                  width: "150px",
                }}
                onClick={() => {
                  setDroppableLists(copiedList);
                  setEnableEditing(!enableEditing);
                  getProject();
                }}
              >
                {locale.cancel}
              </button>
            </div>
          )}
        </div>
      </>}


  </>);
});
