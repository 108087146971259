import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Modal from "react-modal";

import { Input } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";


const ModalHeader = ({ closeModal, header, user }) => {
  return (
    <div>
      <div className="proj-modalHeaderRow">
        <p className="proj-modalHeader font-dm-bold">{header}</p>
        <CloseOutlined
          className="proj-modalHeaderClose"
          onClick={() => closeModal()}
        />
      </div>
      <p className="proj-modalHeaderUser font-dm-medium">{user}</p>
    </div>
  );
};

const SaveBtn = ({ onClick }) => {
  const { locale } = useTranslate();

  return (
    <div className="proj-saveButtonCont">
      <button className="proj-saveButton font-dm-regular" onClick={onClick}>
        {locale.save}
      </button>
    </div>
  );
};

export const EditReceiverModal = ({ isOpen, closeModal, data, saveFunc }) => {
  console.log("EditReceiverModal data", data);
  const [receiver, setReceiver] = useState(null);

  const { locale } = useTranslate();

  useEffect(() => {
    console.log("isOpen updated", isOpen);
    if (isOpen) {
      setReceiver(data.receiver)
    } else {
      setReceiver(null)
    }

  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        className="workmodalBase"
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: "rgba(70, 70, 70, 0.5)",
          },
        }}
        ariaHideApp={false}
      >
        <div className="proj-modalContainer">
          <div className="proj-modal">
            <ModalHeader
              closeModal={() => closeModal()}
              header={"Muokkaa vastaanottajaa"}
            />

            <p className="font-dm-medium label">{"Vastaanottaja"}</p>
            <Input
                         maxLength={50}
              value={receiver}
              onChange={(e) => {
                setReceiver(e.target.value)
                console.log("onChange", e.target.value)
              }}
            />


            <SaveBtn onClick={() => {
              closeModal()
              saveFunc(receiver)
            }
            } />
          </div>
        </div>
      </Modal>
    </>
  );
};
