import "./FileModal.css";

import { Button, DatePicker, Image, Input, InputNumber, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { api, apiSecure } from "../../components/api";
import { getEnvSettings } from "../../config/config";
import { LoadingItem } from "../Loading/Loading";
import { fileTypeFromBuffer } from 'file-type';

const _arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const FileModal = ({ fileData, isModalOpen, setIsModalOpen }) => {
  const envConfig = getEnvSettings();

  const [isLoading, setisLoading] = useState(false)
  const [imgBase64, setImgBase64] = useState(null);
  const [url, setUrl] = useState(null);

  const [isPreviewVisible, setPreviewVisible] = useState(true);




  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getFile = () => {
    setisLoading(true)
    console.log("getFile");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}${fileData.url}`,
      data: {
        fileid: fileData.id,
      },
    };

    apiSecure(req_config)
      .then(async (response) => {
        const bufferR = response.data.data.file.data;
        const fileName = response.data.data.fileName;

        // Tarkista tiedostotyyppi
        var bytes = new Uint8Array(bufferR);
        const fileType = await fileTypeFromBuffer(bytes);

        if (fileType) {
          console.log('Detected file type:', fileType);

          //Kuva
          if (fileType?.mime.includes("image/")) {
            const base64String = _arrayBufferToBase64(bufferR);
            setImgBase64(base64String);
            setisLoading(false)
            return
          }

          //Pdf
          if (fileType?.mime == "application/pdf") {
            const url = window.URL.createObjectURL(
              new Blob([new Uint8Array(bufferR)], { type: "application/pdf" })
            );
            setUrl(url);
            setisLoading(false)
            return
          }

        } else {
          console.log('Unknown file type');
        }

        // Lataa tuntemattomat tiedostot
        const blob = new Blob([new Uint8Array(bufferR)]); // Luo Blob ilman MIME-tyyppiä
        const downloadUrl = window.URL.createObjectURL(blob);

        // Luo ankkuri elementti latausta varten
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName || "downloaded_file"; // Käytä tiedoston nimeä tai oletusnimeä
        document.body.appendChild(link);
        link.click();

        // Poista ankkuri ja vapauta muistia
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
        handleCancel(true)
        setisLoading(false)
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      getFile();
      setPreviewVisible(true)
    } else {
      setImgBase64(null);
      setUrl(null);
    }
  }, [isModalOpen]);


  useEffect(() => {
    console.log('isPreviewVisible', isPreviewVisible)
    if (!isPreviewVisible) {
      handleCancel()
    }
  }, [isPreviewVisible]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        style={{ top: 0, padding: 0 }}
        className="file-modal"
        closeIcon={imgBase64 ? null : ''} // Jos kuva niin piilotetaan close
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isLoading ? <div className="file-modal-loading">
            <LoadingItem color="#000" height={'2vh'} width={'2vh'} />
          </div> : <></>}

          {imgBase64 ? (
            <Image
              height={'0vh'}
              src={`data:image/png;base64,${imgBase64}`}
              preview={{
                visible: isPreviewVisible,
                onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible),
              }}
            />
          ) : (
            <></>
          )}

          {url ? (
            <iframe
              title="pdf"
              src={url}
              style={{ width: "100%", height: "95vh" }}
            ></iframe>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </>
  );
};