import Image from '@tiptap/extension-image'
import { Plugin } from 'prosemirror-state'



export default Image.extend({
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            drop(view, event) {
              const hasFiles =
                event.dataTransfer &&
                event.dataTransfer.files &&
                event.dataTransfer.files.length

              if (!hasFiles) {
                return
              }

              const images = Array.from(event.dataTransfer.files).filter(file =>
                /image/i.test(file.type)
              )

              if (images.length === 0) {
                return
              }

              event.preventDefault()

              const { schema } = view.state
              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY
              })

              images.forEach(image => {
                const reader = new FileReader()

                reader.onload = readerEvent => {
                  const node = schema.nodes.image.create({
                    src: readerEvent.target.result
                  })
                  const transaction = view.state.tr.insert(
                    coordinates.pos,
                    node
                  )
                  view.dispatch(transaction)
                }
                reader.readAsDataURL(image)
              })
            },
            paste(view, event) {
              const hasFiles =
                event.clipboardData &&
                event.clipboardData.files &&
                event.clipboardData.files.length

              if (!hasFiles) {
                return
              }

              const images = Array.from(
                event.clipboardData.files
              ).filter(file => /image/i.test(file.type))

              if (images.length === 0) {
                return
              }

              event.preventDefault()

              const { schema } = view.state

              images.forEach(image => {
                const reader = new FileReader()


                reader.onload = async readerEvent => {
                  const imgBase64 = readerEvent.target.result;
                
                  try {
                    //const NewimgBase64 =  await optimizeBase64Image(imgBase64, 2560, 1440, 1.0);
                
                    const node = schema.nodes.image.create({
                      src: imgBase64
                    });
                    const transaction = view.state.tr.replaceSelectionWith(node);
                    view.dispatch(transaction);
                  } catch (error) {
                    console.error('Error resizing image:', error);
                  }
                };

                reader.readAsDataURL(image)
              })
            }
          }
        }
      })
    ]
  }
})


function optimizeBase64Image(base64, maxWidth, maxHeight, quality = 0.8) {
  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.src = base64;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let width = img.width;
      let height = img.height;

      // Pienennä koon, jos se ylittää maksimiarvot
      if (width > maxWidth || height > maxHeight) {
        if (width > height) {
          height *= maxWidth / width;
          width = maxWidth;
        } else {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      // Säädä JPEG-pakkauslaatu
      const optimizedBase64 = canvas.toDataURL('image/jpeg', quality);

      resolve(optimizedBase64);
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
}