import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";
import "./Project.css";

import {
  FilePdfOutlined,
  MenuOutlined,
  FileOutlined,
  MergeCellsOutlined,
  PlusOutlined,
  DownOutlined,
  UpOutlined,
  LeftOutlined,
  RightOutlined,
  EditOutlined,
  NumberOutlined,
  SortDescendingOutlined
} from "@ant-design/icons";
import Icon from '@mdi/react';
import { mdiCheckboxMarkedOutline, mdiCropSquare, mdiPlayBoxOutline, mdiTruckDeliveryOutline } from '@mdi/js';

import { DatePicker, Dropdown, Menu } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getParsedDate, getParsedTime, getParsedTimeNoZero } from "../../components/globalFunctions";
import { PdfButton } from "../../components/PdfButton/PdfButton";
import {
  ProgressBar,
  ProgressBarMulti
} from "../../components/ProgressBar/ProgressBar";
import { useTranslate } from "../../components/Translate";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import moment, { isMoment } from "moment";


import { getPdf } from "../../components/PdfButton/PdfButton";
import { NewStage } from "./ProjectDroppableEtaps/NewStage";
import { copy, DraggableRowItem, move, RenderListFooter, RenderListHeader, reorder } from "./ProjectDraggable";
import { Header } from '../App/App';
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import { getEnvSettings } from "../../config/config";



const RenderTable = ({
  isMobile,
  isMediumWidth,
  listType,
  tableFilter,
  setTableFilter,
  sortDirection,
  setSortDirection,
  draggingType,

  droppableId,
  items,

  useDragging,
  enableEditing,
  itemsInDroppable,

  footerTotal,
  footerM2,
  footerPaino,
  footerPiirustus,
  footerValmistus,
  footerToimitus,
}) => {

  const today = dayjs();

  console.log('RenderTable', draggingType)


  return (<>
    <div className="project-p-table">
      <RenderListHeader
        isMobile={isMobile}
        isMediumWidth={isMediumWidth}
        listType={listType}
        tableFilter={tableFilter}
        setTableFilter={setTableFilter}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />


      <Droppable
        droppableId={droppableId}
        isDropDisabled={true}
      >
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
            >
              {items.length >= 1 &&
                items.map((item2, index2) => {

                  let disableThisDrag = false
                  let isDelivered = false

                  //Mikäli kuormienhallinta päällä ja elementti on jo toimitettu, ei voi raahailla
                  const toimitusDate = dayjs(item2.ToimitusPVM);
                  if (enableEditing && draggingType === 'delivery' && toimitusDate.isBefore(today, 'day')) {
                    disableThisDrag = true
                    isDelivered = true
                  }

                  if (itemsInDroppable[item2.ValmistusNro]) {
                    disableThisDrag = true
                  }

                  return (
                    <DraggableRowItem
                      key={item2.ValmistusNro}
                      index={index2}
                      itemId={item2.ValmistusNro}
                      item={item2}
                      listType={listType}
                      draggingType={draggingType}
                      dragDisabled={
                        !useDragging || !enableEditing || disableThisDrag
                      }
                      itemsInDroppable={
                        itemsInDroppable
                      }
                      enableEditing={enableEditing}
                      isDelivered={isDelivered}
                      isMobile={isMobile}
                      isMediumWidth={isMediumWidth}
                    />
                  );
                })}

              <div style={{ display: "none" }}>
                {provided.placeholder}
              </div>
            </div>
          );
        }}
      </Droppable>

      <RenderListFooter
        isMobile={isMobile}
        isMediumWidth={isMediumWidth}
        total={footerTotal}
        m2={footerM2}
        paino={footerPaino}
        piirustus={footerPiirustus}
        valmistus={footerValmistus}
        toimitus={footerToimitus}
      />
    </div>
  </>)
}






const BoxHeader = ({ useDragging, handleShowDragging, draggingType, projectId, listType, setListType, enableEditing }) => {
  const { locale } = useTranslate();
  const [pdfLoading, setPdfLoading] = useState(false);
  const envConfig = getEnvSettings();
  const { lang } = useSelector((state) => state.currentUser.user);

  console.log('draggingType', draggingType)

  return <div className="project-box-header">
    <div>
      <p className="project-box-name font-dm-bold">
        {locale.production}
      </p>

      <p className="project-box-label font-dm-medium">
        {listType == "elementit" ? locale.elementgroups : ''}
        {listType == "etapit" ? locale.etaps : ''}
        {listType == "aikataulu" ? locale.schedule : ''}
      </p>
    </div>

    <p className="project-box-right font-dm-bold">




      <div style={{ marginRight: '1em', cursor: 'pointer' }} onClick={async () => {
        console.log('ASDASDASDASDASASD')
        if (pdfLoading) return
        setPdfLoading(true)

        let resp = await getPdf(
          listType == "elementit"
            ? `${envConfig.apiUrlPrefix}getProjectGroupsPdf`
            : `${envConfig.apiUrlPrefix}getProjectStagesPdf`,
          {
            projectId: projectId,
            localeId: lang,
          }
        )
        console.log('PDFresp', resp)
        setPdfLoading(false)


      }
      }>

        {pdfLoading ? <LoadingItem color="#000" /> : <FileOutlined style={{ fontSize: 17 }} />}

      </div>




      <div style={{ marginRight: '1em' }}>


        <Dropdown
          className="lngDropdown"
          menu={{
            items: [
              {
                key: 'drag_etaps', label: (
                  <p
                    className="menuValue font-dm-medium"

                  >
                    {useDragging && draggingType == 'etap' ? locale.etapcontrolhide : locale.etapcontrol}
                  </p>
                ),
                disabled: enableEditing
              },
              {
                key: 'drag_deliverys',
                label: (
                  <p
                    className="menuValue font-dm-medium"
                  >
                    {useDragging && draggingType == 'delivery' ? locale.deliverys_hide : locale.deliverys_control}

                  </p>
                ),
                disabled: enableEditing
              }
            ],
            onClick: ({ key }) => {
              console.log(`Click on item ${key}`);
              if (enableEditing) return

              if (key === 'drag_etaps') {
                handleShowDragging('etap')
              }

              if (key === 'drag_deliverys') {
                handleShowDragging('delivery')
              }
            }
          }
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <SortDescendingOutlined style={{ fontSize: 17, marginLeft: 10, marginRight: 5 }} />
        </Dropdown>


      </div>

      <Dropdown
        className="lngDropdown"
        menu={{
          items: [


            listType !== "elementit" ?
              {
                key: 'elementti', label: (
                  <p
                    className="menuValue font-dm-medium"
                    onClick={() => {
                      setListType("elementit")
                    }}
                  >
                    {locale.elementgroups}
                  </p>
                ),
              } : undefined,


            listType !== "etapit" ?
              {
                key: 'etappi',
                label: (
                  <p
                    className="menuValue font-dm-medium"
                    onClick={() => {
                      setListType("etapit")
                    }}
                  >
                    {locale.etaps}
                  </p>
                ),
              } : undefined,


            listType !== "aikataulu" ?

              {
                key: 'aikataulu', label: (
                  <p
                    className="menuValue font-dm-medium"
                    onClick={() => {
                      setListType("aikataulu")
                    }}
                  >
                    {locale.schedule}
                  </p>
                ),
              } : undefined
          ]
        }
        }
        trigger={["click"]}
        placement="bottomRight"
      >
        <MenuOutlined style={{ fontSize: 17, marginLeft: 10, marginRight: 5 }} />
      </Dropdown>
    </p>
  </div>

}


export const ProjectElements = ({ navigate, projectId, projectRootData, projectData, animation, listType, setListType, enableEditing, itemsInDroppable, useDragging, handleShowDragging, draggingType }) => {
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isMediumWidth = useMediaQuery({ maxWidth: 1100 });

  const [elementListOpen, setElementListOpen] = useState({});
  const [etappiListOpen, setEtappiListOpen] = useState({});
  const [aikatauluListOpen, setAikatauluListOpen] = useState({});

  const { tehtaat, lang, selectedFactory } = useSelector((state) => state.currentUser.user);



  const [noEtappiTotal, setNoEtappiTotal] = useState({});
  const [etappiTotal, setEtappiTotal] = useState([]);
  const [parentEtappiTotal, setParentEtappiTotal] = useState([]);


  const [tableFilter, setTableFilter] = useState("tunnus");
  const [sortDirection, setSortDirection] = useState("asc");

  const [aikatauluData, setAikatauluData] = useState([]);
  const [noAikatauluData, setNoAikatauluData] = useState([]);

  const today = dayjs();
  // Lasketaan etappien elementtien yhteisarvot
  useEffect(() => {
    let tmp = {
      total: 0,
      m2: 0,
      paino: 0,
      piirustus: 0,
      valmistus: 0,
      toimitus: 0,
    }

    let noEtapValues = JSON.parse(JSON.stringify(tmp))
    let EtapValueArray = []

    projectData?.elementit?.map((itm, idx) => {
      if (itm.Etappi) {
        if (!EtapValueArray[itm.Etappi]) {
          EtapValueArray[itm.Etappi] = JSON.parse(JSON.stringify(tmp))
        }
        EtapValueArray[itm.Etappi].total += 1
        EtapValueArray[itm.Etappi].m2 += itm.M2
        EtapValueArray[itm.Etappi].paino += itm.Paino
        EtapValueArray[itm.Etappi].piirustus += itm.Piirustus ? 1 : 0
        EtapValueArray[itm.Etappi].valmistus += itm.ValmistusPVM ? 1 : 0
        EtapValueArray[itm.Etappi].toimitus += itm.ToimitusPVM ? 1 : 0
      } else {
        noEtapValues.total += 1
        noEtapValues.m2 += itm.M2
        noEtapValues.paino += itm.Paino
        noEtapValues.piirustus += itm.Piirustus ? 1 : 0
        noEtapValues.valmistus += itm.ValmistusPVM ? 1 : 0
        noEtapValues.toimitus += itm.ToimitusPVM ? 1 : 0

      }
    })



    //Lasketaan juurietapin arvot
    let parentEtapTotal = []
    projectData?.elementit?.map((itm, idx) => {
      if (itm.Etappi) {

        let etapId

        let etapData = projectData.etapit.find(etap => etap.EtappiNro === itm.Etappi)

        if (etapData?.EtappiNro) etapId = etapData?.EtappiNro
        if (etapData?.ParentID) etapId = etapData?.ParentID

        if (!etapId) return

        if (!parentEtapTotal[etapId]) {
          parentEtapTotal[etapId] = JSON.parse(JSON.stringify(tmp))
        }

        parentEtapTotal[etapId].total += 1
        parentEtapTotal[etapId].m2 += itm.M2
        parentEtapTotal[etapId].paino += itm.Paino
        parentEtapTotal[etapId].piirustus += itm.Piirustus ? 1 : 0
        parentEtapTotal[etapId].valmistus += itm.ValmistusPVM ? 1 : 0
        parentEtapTotal[etapId].toimitus += itm.ToimitusPVM ? 1 : 0
      }
    })



    setNoEtappiTotal(noEtapValues)
    setEtappiTotal(EtapValueArray)

    setParentEtappiTotal(parentEtapTotal)
  }, [projectData]);





  // Lasketaan aikataulu elementtien yhteisarvot
  useEffect(() => {
    let tmp = {
      total: 0,
      m2: 0,
      paino: 0,
      piirustus: 0,
      valmistus: 0,
      toimitus: 0,
      elements: []
    }

    let noAikatauluArr = JSON.parse(JSON.stringify(tmp))
    let aikatauluArr = {}

    projectData?.elementit?.map((itm, idx) => {
      if (itm.ArvioituToimitusPvm) {
        let ndate = getParsedDate(itm.ArvioituToimitusPvm)

        if (!aikatauluArr[ndate]) {
          aikatauluArr[ndate] = JSON.parse(JSON.stringify(tmp))
        }

        aikatauluArr[ndate].total += 1
        aikatauluArr[ndate].m2 += itm.M2
        aikatauluArr[ndate].paino += itm.Paino
        aikatauluArr[ndate].piirustus += itm.Piirustus ? 1 : 0
        aikatauluArr[ndate].valmistus += itm.ValmistusPVM ? 1 : 0
        aikatauluArr[ndate].toimitus += itm.ToimitusPVM ? 1 : 0

        aikatauluArr[ndate].elements.push(itm)
      } else {
        noAikatauluArr.total += 1
        noAikatauluArr.m2 += itm.M2
        noAikatauluArr.paino += itm.Paino
        noAikatauluArr.piirustus += itm.Piirustus ? 1 : 0
        noAikatauluArr.valmistus += itm.ValmistusPVM ? 1 : 0
        noAikatauluArr.toimitus += itm.ToimitusPVM ? 1 : 0
        noAikatauluArr.elements.push(itm)
      }

    })


    console.log('test4 aikatauluArr, ', aikatauluArr)
    console.log('test4 noAikatauluArr, ', noAikatauluArr)

    setAikatauluData(aikatauluArr)
    setNoAikatauluData(noAikatauluArr)
  }, [projectData]);




  if (!projectData.loaded) {
    return (
      <>
        <p>Ei tietoa</p>
        <button onClick={() => navigate(-1)}>Go back</button>
      </>
    );
  }

  const handleOpenElement = (id) => {
    let OpenList = elementListOpen;
    let IsOpen = OpenList[id];

    OpenList[id] = !IsOpen;

    setElementListOpen({
      ...OpenList,
    });
  };

  const handleOpenEtappi = (id) => {
    let OpenList = etappiListOpen;
    let IsOpen = OpenList[id];

    OpenList[id] = !IsOpen;

    setEtappiListOpen({
      ...OpenList,
    });
  };

  const handleOpenAikataulu = (id) => {
    let OpenList = aikatauluListOpen;
    let IsOpen = OpenList[id];

    OpenList[id] = !IsOpen;

    setAikatauluListOpen({
      ...OpenList,
    });
  };





  const sortTableItems = (items) => {
            console.log('ITEMSTEST', items)

    let nItems = [...items]
    /*     console.log('ITEMSTEST', nItems) */

    const sortValue = (key) => {
      nItems.sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];

        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      });
    }

    const sortDate = (key) => {
      nItems.sort((a, b) => {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);

        if (isNaN(dateA) && isNaN(dateB)) {
          return 0; // If neither has a valid date, maintain original order
        }

        if (isNaN(dateA)) {
          return 1; // a should come after b if a's date is invalid
        }

        if (isNaN(dateB)) {
          return -1; // a should come before b if b's date is invalid
        }

        // Both dates are valid, sort them
        return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
      });
    };



    const sortString = (key, secondaryKey = null) => {
      nItems.sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];


        const comparison = sortDirection === 'asc' ? valueA?.localeCompare(valueB) : valueB?.localeCompare(valueA);

        if (comparison !== 0 || !secondaryKey) {
          return comparison;
        }

        // If primary keys are equal, sort by secondary key
        const secondaryValueA = a[secondaryKey];
        const secondaryValueB = b[secondaryKey];
        return secondaryValueA - secondaryValueB
      });
    };

    if (tableFilter === "tunnus") sortString("Tunnus", "ValmistusNro")
    if (tableFilter === "id") sortValue("ValmistusNro")
    if (tableFilter === "pituus") sortValue("Pituus")
    if (tableFilter === "korkeus") sortValue("Korkeus")
    if (tableFilter === "paksuus") sortValue("Paksuus")
    if (tableFilter === "m2") sortValue("M2")
    if (tableFilter === "paino") sortValue("Paino")
    if (tableFilter === "piirustus") sortDate("Piirustus")
    if (tableFilter === "valmistus") sortDate("ValmistusPVM")
    if (tableFilter === "toimitus") sortDate("ToimitusPVM")

    if (tableFilter === "lastcol") {
      if (listType === "elementit") sortString("EtappiNimi")
      if (listType === "etapit") sortString("ValmistusTehdas")
      if (listType === "aikataulu") sortValue("KuormakirjaNumero")
    }

    return nItems
  }




  return (<div>

    {/* Elementtiryhmät */}
    {listType == "elementit" && (
      <div className="project-box">

        <BoxHeader enableEditing={enableEditing} useDragging={useDragging} handleShowDragging={handleShowDragging} draggingType={draggingType} projectId={projectId} listType={listType} setListType={setListType} />

        <ProgressBar
          style={{ marginLeft: isMobile ? 0 : 0, marginRight: isMobile ? 0 : 0, marginBottom: 10 }}
          backgroundColor={"#14DC40"}
          data1={projectRootData.tiedot.TilattuKplYht}
          data2={projectRootData.tiedot.ValmistettuKplYht}
        />

        {projectData.elementtiryhmat.length >= 1 &&
          projectData.elementtiryhmat.map((item, index) => {
            console.log('TEST22', item)
            let isVälitys = false;

            if (
              tehtaat[item.Tehdas] &&
              tehtaat[item.Tehdas].name == "Välitys"
            ) {
              isVälitys = true;
            }

            return (
              <div
                key={item.ElementtiRyhmaNro}
                className="project-p-item"
              >
                <div
                  className="project-p-header"
                  onClick={() => {
                    if (!isVälitys)
                      handleOpenElement(item.ElementtiRyhmaNro);
                  }}
                >
                  <div className="item-title">
                    <p
                      className={`name ${isVälitys ? "font-dm-regular" : "font-dm-bold"
                        }`}
                    >
                      {item.ElementtiRyhma}
                    </p>

                    <div className="item-footer">
                      <p className="factory font-dm-regular">
                        {tehtaat[item.Tehdas] &&
                          tehtaat[item.Tehdas].name}
                      </p>
                      {isMobile ?
                        <div className="item-numbers">
                          <p className="number font-dm-regular">
                            {item.TilattuKpl || 0} <Icon path={mdiCropSquare} size={0.8} style={{ marginLeft: 2 }} />
                          </p>
                          <p className="number font-dm-regular">
                            {item.PiirustuksiaKpl || 0} <Icon path={mdiPlayBoxOutline} size={0.8} style={{ marginLeft: 2 }} />

                          </p>

                          <p className="number font-dm-regular">
                            {item.ValmistettuKpl || 0} <Icon path={mdiCheckboxMarkedOutline} size={0.8} style={{ marginLeft: 2 }} />
                          </p>

                          <p className="number font-dm-regular">
                            {item.ToimitettuKpl || 0} <Icon path={mdiTruckDeliveryOutline} size={0.8} style={{ marginLeft: 2 }} />
                          </p>
                        </div>
                        : <></>}
                    </div>
                  </div>

                  {!isMobile ? <>
                    <div className="item-labelvalue">
                      <p className="label font-dm-regular">
                        {locale.ordered}
                      </p>
                      <p
                        className={`value ${isVälitys
                          ? "font-dm-regular"
                          : "font-dm-bold"
                          }`}
                      >
                        {item.TilattuKpl || 0}
                      </p>
                    </div>

                    <div className="item-labelvalue">
                      <p className="label font-dm-regular">
                        {locale.blueprints}
                      </p>
                      <p
                        className={`value ${isVälitys
                          ? "font-dm-regular"
                          : "font-dm-bold"
                          }`}
                      >
                        {item.PiirustuksiaKpl || 0}
                      </p>
                    </div>

                    <div className="item-labelvalue">
                      {!isVälitys && (
                        <>
                          <p className="label font-dm-regular">
                            {locale.prepared}
                          </p>
                          <p className="value font-dm-bold">
                            {item.ValmistettuKpl || 0}
                          </p>
                        </>
                      )}
                    </div>

                    <div className="item-labelvalue">
                      {!isVälitys && (
                        <>
                          <p className="label font-dm-regular">
                            {locale.booked}
                          </p>
                          <p className="value font-dm-bold">
                            {item.ToimitettuKpl || 0}
                          </p>
                        </>
                      )}
                    </div>
                  </>
                    : <></>}

                  <div className="item-arrow">
                    {elementListOpen[item.ElementtiRyhmaNro]
                      ? <UpOutlined className="item-arrow-icon" style={{}} />
                      : <RightOutlined className="item-arrow-icon" style={{}} />
                    }
                  </div>
                </div>

                {elementListOpen[item.ElementtiRyhmaNro] && item?.data?.length >= 1 && (
                  <>
                    <RenderTable
                      isMobile={isMobile}
                      isMediumWidth={isMediumWidth}
                      listType={listType}
                      tableFilter={tableFilter}
                      setTableFilter={setTableFilter}
                      sortDirection={sortDirection}
                      setSortDirection={setSortDirection}
                      draggingType={draggingType}

                      droppableId={
                        "GROUP_" + item.ElementtiRyhmaNro
                      }

                      items={[...sortTableItems(item.data)]}

                      useDragging={useDragging}
                      enableEditing={enableEditing}
                      itemsInDroppable={itemsInDroppable}

                      footerTotal={item?.data?.length}
                      footerM2={item.Neliot}
                      footerPaino={item.Tonnit}
                      footerPiirustus={item.PiirustuksiaKpl}
                      footerValmistus={item.ValmistettuKpl}
                      footerToimitus={item.ToimitettuKpl}
                    />
                  </>
                )}
              </div>
            );
          })}
      </div>
    )}


    {/* Etapit */}
    {listType == "etapit" && (
      <>
        <div className="project-box">
          <BoxHeader enableEditing={enableEditing} useDragging={useDragging} handleShowDragging={handleShowDragging} draggingType={draggingType} projectId={projectId} listType={listType} setListType={setListType} />

          {projectData.etapit.map((etappiBase, indx) => {
            if (etappiBase.ParentID != 0) return;

            return (
              <>
                <div key={etappiBase.EtappiNro} className="project-p-item">
                  <div
                    className="project-p-header"
                    onClick={() => {
                      handleOpenEtappi(etappiBase.EtappiNro);
                    }}
                  >
                    <div className="item-title">
                      <p className={`name font-dm-bold`}>
                        {etappiBase.Etappi}
                      </p>

                      <div className="item-footer">
                        {!isMobile &&
                          <p className="factory font-dm-regular" style={{ fontSize: 13, alignSelf: 'center' }}>
                            {(etappiBase?.ArvioituToimituksenAlku || etappiBase?.ArvioituToimituksenLoppu) &&
                              (etappiBase?.ArvioituToimituksenAlku
                                ? getParsedDate(
                                  etappiBase?.ArvioituToimituksenAlku
                                )
                                : "") +
                              " - " +
                              (etappiBase?.ArvioituToimituksenLoppu
                                ? getParsedDate(
                                  etappiBase?.ArvioituToimituksenLoppu
                                )
                                : "")
                            }
                          </p>
                        }

                        {isMobile ?
                          <div className="item-numbers">
                            <p className="number font-dm-regular">
                              {parentEtappiTotal[etappiBase.EtappiNro]?.total || 0} <Icon path={mdiCropSquare} size={0.8} style={{ marginLeft: 2 }} />
                            </p>
                            <p className="number font-dm-regular">
                              {parentEtappiTotal[etappiBase.EtappiNro]?.piirustus || 0} <Icon path={mdiPlayBoxOutline} size={0.8} style={{ marginLeft: 2 }} />

                            </p>

                            <p className="number font-dm-regular">
                              {parentEtappiTotal[etappiBase.EtappiNro]?.valmistus || 0} <Icon path={mdiCheckboxMarkedOutline} size={0.8} style={{ marginLeft: 2 }} />
                            </p>

                            <p className="number font-dm-regular">
                              {parentEtappiTotal[etappiBase.EtappiNro]?.toimitus || 0} <Icon path={mdiTruckDeliveryOutline} size={0.8} style={{ marginLeft: 2 }} />
                            </p>
                          </div>
                          : <></>}
                      </div>
                    </div>

                    {!isMobile ? <>
                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.ordered}
                        </p>
                        <p
                          className={`value font-dm-bold`}
                        >
                          {parentEtappiTotal[etappiBase.EtappiNro]?.total || 0}
                        </p>
                      </div>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.blueprints}
                        </p>
                        <p
                          className={`value font-dm-bold`}
                        >
                          {parentEtappiTotal[etappiBase.EtappiNro]?.piirustus || 0}
                        </p>
                      </div>

                      <div className="item-labelvalue">

                        <p className="label font-dm-regular">
                          {locale.prepared}
                        </p>
                        <p className="value font-dm-bold">
                          {parentEtappiTotal[etappiBase.EtappiNro]?.valmistus || 0}
                        </p>

                      </div>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.booked}
                        </p>
                        <p className="value font-dm-bold">
                          {parentEtappiTotal[etappiBase.EtappiNro]?.toimitus || 0}
                        </p>
                      </div>
                    </>
                      : <></>}
                  </div>


                  {etappiListOpen[etappiBase.EtappiNro] && (
                    <>
                      <div className="dividerTest"></div>
                      <ProgressBar
                        style={{ marginLeft: isMobile ? 0 : 10, marginRight: isMobile ? 0 : 10, marginBottom: 10 }}
                        backgroundColor={"#14DC40"}
                        data1={parentEtappiTotal[etappiBase.EtappiNro]?.total || 0}
                        data2={parentEtappiTotal[etappiBase.EtappiNro]?.valmistus || 0}
                      />

                      <ProgressBar
                        style={{ marginLeft: isMobile ? 0 : 10, marginRight: isMobile ? 0 : 10, marginBottom: 10 }}
                        backgroundColor={"#2B4BF2"}
                        data1={parentEtappiTotal[etappiBase.EtappiNro]?.total || 0}
                        data2={parentEtappiTotal[etappiBase.EtappiNro]?.toimitus || 0}
                      />


                      {projectData.etapit.map((etappiItem, indx2) => {
                        if ((etappiBase.EtappiNro != etappiItem.EtappiNro) && (etappiBase.EtappiNro != etappiItem.ParentID)) return;

                        return (
                          <div key={etappiItem.EtappiNro}>
                            {etappiBase.EtappiNro === etappiItem.ParentID ?
                              <p className="project-box-name font-dm-bold" style={{ marginBottom: 0 }}>
                                {etappiItem.Etappi}
                              </p>
                              : <></>}


                            <RenderTable
                              isMobile={isMobile}
                              isMediumWidth={isMediumWidth}
                              listType={listType}
                              tableFilter={tableFilter}
                              setTableFilter={setTableFilter}
                              sortDirection={sortDirection}
                              setSortDirection={setSortDirection}
                              draggingType={draggingType}

                              droppableId={
                                "GROUP_" + etappiItem.EtappiNro
                              }

                              items={[...sortTableItems(projectData.elementit.filter(nItem => nItem.Etappi === etappiItem.EtappiNro))]}

                              useDragging={useDragging}
                              enableEditing={enableEditing}
                              itemsInDroppable={itemsInDroppable}

                              footerTotal={etappiTotal[etappiItem.EtappiNro]?.total}
                              footerM2={etappiTotal[etappiItem.EtappiNro]?.m2}
                              footerPaino={etappiTotal[etappiItem.EtappiNro]?.paino}
                              footerPiirustus={etappiTotal[etappiItem.EtappiNro]?.piirustus}
                              footerValmistus={etappiTotal[etappiItem.EtappiNro]?.valmistus}
                              footerToimitus={etappiTotal[etappiItem.EtappiNro]?.toimitus}
                            />
                          </div>
                        )
                      })}
                    </>
                  )}

                </div>
              </>
            )
          })}
        </div>


        {/* ELEMENTIT JOILlA EI OLE VALITTUA ETAPPIA */}
        {projectData?.elementit?.filter(itm2 => !itm2.Etappi).length >= 1 && (
          <div className="project-box">
            <p className="project-box-name font-dm-bold">
              {locale.nophases}
            </p>

            <RenderTable
              isMobile={isMobile}
              isMediumWidth={isMediumWidth}
              listType={listType}
              tableFilter={tableFilter}
              setTableFilter={setTableFilter}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              draggingType={draggingType}

              droppableId={
                "GROUP_OTHERS"
              }

              items={[...sortTableItems(projectData.elementit.filter(nItem => !nItem.Etappi))]}

              useDragging={useDragging}
              enableEditing={enableEditing}
              itemsInDroppable={itemsInDroppable}

              footerTotal={noEtappiTotal?.total}
              footerM2={noEtappiTotal?.m2}
              footerPaino={noEtappiTotal?.paino}
              footerPiirustus={noEtappiTotal?.piirustus}
              footerValmistus={noEtappiTotal?.valmistus}
              footerToimitus={noEtappiTotal?.toimitus}
            />
          </div>
        )}
      </>
    )}


    {/* AIKATAULU NÄKYMÄ */}
    {listType == "aikataulu" && (
      <>
        <div className="project-box">
          <BoxHeader enableEditing={enableEditing} useDragging={useDragging} handleShowDragging={handleShowDragging} draggingType={draggingType} projectId={projectId} listType={listType} setListType={setListType} />


          {Object.keys(aikatauluData).map((dateKey, indx) => {
            const dateData = aikatauluData[dateKey]

            return (
              <>
                <div key={indx} className="project-p-item">

                  <div
                    className="project-p-header"
                    onClick={() => {
                      handleOpenAikataulu(dateKey);
                    }}
                  >
                    <div className="item-title">
                      <p className={`name font-dm-bold`}>
                        {dateKey}
                      </p>



                      {isMobile ?
                        <div className="item-numbers">
                          <p className="number font-dm-regular">
                            {dateData?.total || 0} <Icon path={mdiCropSquare} size={0.8} style={{ marginLeft: 2 }} />
                          </p>
                          <p className="number font-dm-regular">
                            {dateData?.piirustus || 0} <Icon path={mdiPlayBoxOutline} size={0.8} style={{ marginLeft: 2 }} />

                          </p>

                          <p className="number font-dm-regular">
                            {dateData?.valmistus || 0}<Icon path={mdiCheckboxMarkedOutline} size={0.8} style={{ marginLeft: 2 }} />
                          </p>

                          <p className="number font-dm-regular">
                            {dateData?.toimitus || 0} <Icon path={mdiTruckDeliveryOutline} size={0.8} style={{ marginLeft: 2 }} />
                          </p>
                        </div>
                        : <></>}


                    </div>


                    {!isMobile ? <>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.ordered}
                        </p>
                        <p
                          className={`value font-dm-bold`}
                        >
                          {dateData?.total || 0}
                        </p>
                      </div>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.blueprints}
                        </p>
                        <p
                          className={`value font-dm-bold`}
                        >
                          {dateData?.piirustus || 0}
                        </p>
                      </div>

                      <div className="item-labelvalue">

                        <p className="label font-dm-regular">
                          {locale.prepared}
                        </p>
                        <p className="value font-dm-bold">
                          {dateData?.valmistus || 0}
                        </p>

                      </div>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.booked}
                        </p>
                        <p className="value font-dm-bold">
                          {dateData?.toimitus || 0}
                        </p>
                      </div>
                    </> : <></>}
                  </div>


                  {aikatauluListOpen[dateKey] && (
                    <>

                      <div className="dividerTest"></div>

                      <ProgressBar
                        style={{ marginLeft: isMobile ? 0 : 10, marginRight: isMobile ? 0 : 10, marginBottom: 10 }}

                        backgroundColor={"#14DC40"}
                        data1={dateData?.total || 0}
                        data2={dateData?.valmistus || 0}
                      />

                      <ProgressBar
                        style={{ marginLeft: isMobile ? 0 : 10, marginRight: isMobile ? 0 : 10, marginBottom: 10 }}

                        backgroundColor={"#2B4BF2"}
                        data1={dateData?.total || 0}
                        data2={dateData?.toimitus || 0}
                      />



                      <RenderTable
                        isMobile={isMobile}
                        isMediumWidth={isMediumWidth}
                        listType={listType}
                        tableFilter={tableFilter}
                        setTableFilter={setTableFilter}
                        sortDirection={sortDirection}
                        setSortDirection={setSortDirection}
                        draggingType={draggingType}

                        droppableId={
                          "GROUP_" + dateKey
                        }

                        items={[...sortTableItems(dateData.elements)]}

                        useDragging={useDragging}
                        enableEditing={enableEditing}
                        itemsInDroppable={itemsInDroppable}

                        footerTotal={dateData?.total}
                        footerM2={dateData?.m2}
                        footerPaino={dateData?.paino}
                        footerPiirustus={dateData?.piirustus}
                        footerValmistus={dateData?.valmistus}
                        footerToimitus={dateData?.toimitus}
                      />
                    </>
                  )}

                </div>
              </>
            )
          })}
        </div>


        {/* ELEMENTIT JOILlA EI OLE aikataulua */}
        {noAikatauluData.elements.length >= 1 && (
          <div className="project-box">
            <p className="project-box-name font-dm-bold">
              {locale.noscheduletext}
            </p>


            <RenderTable
              isMobile={isMobile}
              isMediumWidth={isMediumWidth}
              listType={listType}
              tableFilter={tableFilter}
              setTableFilter={setTableFilter}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              draggingType={draggingType}

              droppableId={
                "GROUP_OTHERS"
              }

              items={[...sortTableItems(noAikatauluData.elements)]}

              useDragging={useDragging}
              enableEditing={enableEditing}
              itemsInDroppable={itemsInDroppable}

              footerTotal={noAikatauluData?.total}
              footerM2={noAikatauluData?.m2}
              footerPaino={noAikatauluData?.paino}
              footerPiirustus={noAikatauluData?.piirustus}
              footerValmistus={noAikatauluData?.valmistus}
              footerToimitus={noAikatauluData?.toimitus}
            />
          </div>
        )}
      </>
    )}


    {/* TOIMITUS BOXI */}
    {listType == "elementit" && (
      <div className="project-box">
        <p className="project-box-name font-dm-bold">{locale.deliveryheader}</p>

        <ProgressBar
          style={{ marginLeft: isMobile ? 0 : 0, marginRight: isMobile ? 0 : 0, marginBottom: 10 }}

          backgroundColor={"#2B4BF2"}
          data1={projectRootData.tiedot.ValmistettuKplYht}
          data2={projectRootData.tiedot.ToimitettuKplYht}
        />

        {projectData.kuormakirja.length >= 1 &&
          projectData.kuormakirja.map((item, index) => {
            let hour = getParsedTime(item.PurkuKlo)
            console.log('item', item)

            let color = '#CDAF14'; // Uusi = keltainen

            //Jos lastauksessa/lastattu mutta ei toimitettu vihreä
            if (item.LastausAlku || item.LastausLoppu) {
              color = "#2BF263"
            }

            //Jos toimitettu, harmaaksi
            if (item.OnkoToimitettu) {
              color = "#C0C0C0 "
            }


            //Uusi ketlanen

            //Lastattu = vihreä

            //TOimitettu harmaa

            return (
              <>
                <div
                  key={item.KuormakirjaNumero}
                  className="project-delivery-item"
                >
                  <div className="project-delivery-circle" style={{ backgroundColor: color }} />

                  <div className="item-left">

                    {/* Eka rivi */}
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <span className={`name font-dm-bold`}>
                        {item.KuormakirjaNumero}
                      </span>

                      <span className={`kpl font-dm-bold`}>
                        {`${item?.Maara || 0}`}
                        <span className={`label font-dm-regular`}>
                          kpl
                        </span>
                      </span>

                      <span className={`weight font-dm-bold`}>
                        {`${item?.ElementtienPaino?.toFixed(1) || 0}`}
                        <span className={`label font-dm-regular`}>
                          t
                        </span>
                      </span>
                    </div>


                    {/* Toinen rivi */}
                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }}>
                      <span className="factory font-dm-medium">
                        {tehtaat[item.Tehdas] &&
                          tehtaat[item.Tehdas].name}
                      </span>

                      {item.AutonNimi && !isMobile &&
                        <span className="vehicle font-dm-medium">
                          {item.AutonNimi}
                        </span>
                      }

                      {item.SuunniteltuLastausaika && !item.LastausLoppu && !isMediumWidth && (
                        <span className="schedule font-dm-medium">
                          <span className={""}>
                            Lastaus
                          </span>
                          <span className={"date"}>
                            {getParsedDate(item.SuunniteltuLastausaika)}
                          </span>
                          <span className={"hour"}>
                            {getParsedTimeNoZero(item.SuunniteltuLastausaikaKlo)}
                          </span>
                        </span>
                      )}

                      {item.LastausLoppu && !isMediumWidth && (
                        <span className="schedule font-dm-medium">
                          <span className={""}>
                            Lastattu
                          </span>
                          <span className={"date"}>
                            {getParsedDate(item.LastausLoppu)}
                          </span>
                          <span className={"hour"}>
                            {getParsedTimeNoZero(item.LastausLoppu)}
                          </span>
                        </span>
                      )}


                      {item?.ToimitusPVM && isMobile && (
                        <span className="schedule font-dm-medium">
                          <span className={""}>
                            Toimitus
                          </span>
                          <span className={"date"}>
                            {getParsedDate(item?.ToimitusPVM)}
                          </span>
                          <span className={"hour"}>
                            {hour}
                          </span>
                        </span>
                      )}

                    </div>
                  </div>



                  {item?.ToimitusPVM ?
                    <div className="item-right">

                      {!isMobile ? <>
                        {!isMediumWidth &&
                          <span className={"label font-dm-medium"}>
                            Toimitus
                          </span>
                        }
                        <span className={`date font-dm-bold ${item.OnkoToimitettu ? 'delivered' : ''}`}>
                          {getParsedDate(item?.ToimitusPVM) || 0}
                        </span>
                        <span className={`hour font-dm-bold ${item.OnkoToimitettu ? 'delivered' : ''}`}>
                          {hour}
                        </span>
                      </> : <></>}
                      <div className="item-pdf">
                        <PdfButton
                          url={`${envConfig.apiUrlPrefix}getDeliveryPdf`}
                          data={{
                            deliveryId: item.KuormakirjaNumero,
                            projectId: projectId,
                            useSqlLocale: false,
                            localeId: lang,
                          }}
                        />
                      </div>
                    </div>
                    : <></>}
                </div>
              </>
            );
          })}
      </div>
    )}
  </div>

  );
};
