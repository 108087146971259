import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSecure } from "../../../components/api";
import { Loading, LoadingItem } from "../../../components/Loading/Loading";

import { useTranslate } from "../../../components/Translate";
import "./ProjectNote.css";


import { useMediaQuery } from "react-responsive";
import { getEnvSettings } from "../../../config/config";

import { PlusOutlined, FileFilled } from '@ant-design/icons';

import { getParsedDate } from "../../../components/globalFunctions";
import { ProjectNote } from "./ProjectNote";
import { Link, useNavigate, useParams } from "react-router-dom";



export const ProjectNotes = ({ }) => {
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();
  let { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false);

  const [notes, setNotes] = useState();

  const [selectedNote, setSelectedNote] = useState(null);


  const getNotes = () => {
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getProjectNotes`,
      data: {
        projectId: id,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        setNotes(response.data.data)
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    console.log('getNotes')
    getNotes()
  }, []);

  const addNote = () => {
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}newProjectNote`,
      data: {
        projectId: id
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);

        const { status, noteId } = response?.data
        if (status) {
          getNotes()

          openNote(noteId)
        }

      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };



  const openNote = (nodeId) => {
    navigate(`/project/${id}/note/${nodeId}`);
  };



  return (<div className={`projectpage-content`}>

    {selectedNote && <ProjectNote noteId={selectedNote} refreshData={getNotes} closeNote={() => {
      setSelectedNote(null)
    }} />}

    {!selectedNote && <>
      <div className="project-info">
        <p className="project-info-title font-dm-bold">{locale.notes}</p>


        {!isLoading ? <div className="project-notes">
          {notes?.map((itm) => {
            return <Link to={`/project/${id}/note/${itm?.MuistioNro}`} key={itm?.MuistioNro} className="project-noteitm clickable">
              <FileFilled className="project-noteitm-icon" />
              <div className="project-noteitm-title font-dm-bold">{itm?.Otsikko}</div>
              <div className="project-noteitm-date font-dm-medium">{getParsedDate(itm?.Paiva)}</div>
            </Link>
          })}

          <div className="project-noteitm-addCard" onClick={() => {
            addNote()
          }}>
            <PlusOutlined className="project-noteitm-addIcon" />
          </div>
        </div> :
          <div className="project-info-loading">
            <LoadingItem color="#000" height={'2vh'} width={'2vh'} />
          </div>
        }
      </div>
    </>}
  </div>


  );
};
