import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import "./FileUpload.css";
import { Input, notification } from "antd";

import { getEnvSettings } from "../../config/config";
import { apiSecure } from "../api";

import { useDropzone } from 'react-dropzone'



const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676',
  height: 200
};

const rejectStyle = {
  borderColor: '#ff1744'
};


const minSize = 1; // Vähintään 1 tavu (ei tyhjiä tiedostoja)
const maxSize = 10 * (1024 * 1024) // 10mb max file upload limit

function formatSizeInMB(bytes) {
  const megabytes = bytes / (1024 * 1024);
  const formattedSize = megabytes % 1 === 0 ? megabytes.toFixed(0) : megabytes.toFixed(2);

  // Tarkistetaan, onko tulos 0.00 ja palautetaan 0.01
  return `${formattedSize === '0.00' ? '0.01' : formattedSize} MB`;
}


const nameMaxLength = 50;

function nameLengthValidator(file) {
  console.log('file', file)

  if (file?.name?.length > nameMaxLength) {
    return {
      code: "name-too-large",
      message: `Name is larger than ${nameMaxLength} characters`
    };
  }

  return null
}

export const FileUploadDropZone = ({ apiUrl, id, refreshData}) => {

  /* Tiedoston tallennus kutsu bäkkäriin */
  const fileUploadReq = (apiUrl, id, file, fileName) => {
    const envConfig = getEnvSettings();
  
  
    const formData = new FormData();
    formData.append("uploaded_file", file);
    formData.append("id", id);
  
    let req_config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      url: `${envConfig.apiUrlPrefix}${apiUrl}`,
      data: formData,
    };
  
    apiSecure(req_config)
      .then((response) => {
        refreshData();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      fileUploadReq(apiUrl, id, file, file.name)
    })

    // Näytä ilmoitus liian suurista tiedostoista
    fileRejections.forEach(({ file, errors }) => {
      console.log('fileRejections', file, errors)

      errors.forEach((error) => {
        if (error.code === 'file-too-large') {
          notification.open({
            message: 'Tiedoston hylkäys',
            description: `${file.name} ylittää ${formatSizeInMB(maxSize)} maksimikoon.`,
            placement: 'top',
            type: 'error'
          });
        }

        if (error.code === 'file-too-small') {
          notification.open({
            message: 'Tiedoston hylkäys',
            description: `${file.name} tyhjiä tiedostoja ei voi lisätä.`,
            placement: 'top',
            type: 'error'
          });
        }

        if (error.code === 'too-many-files') {
          notification.open({
            message: 'Tiedoston hylkäys',
            description: `Maksimissaan 1 tiedosto kerrallaan!`,
            placement: 'top',
            type: 'error'
          });
        }

        if (error.code === 'name-too-large') {
          notification.open({
            message: 'Tiedoston hylkäys',
            description: `Tiedoston nimi ylittää ${nameMaxLength} merkkirajan.`,
            placement: 'top',
            type: 'error'
          });
        }


      });
    });

  }, [])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    minSize,
    maxSize,
    maxFiles: 1,
    validator: nameLengthValidator
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  console.log('isFocused', isFocused)
  console.log('isDragAccept', isDragAccept)
  console.log('isDragReject', isDragReject)

  return (
    <div className="file-upload-zone">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="font-dm-medium">Raahaa tiedostoja tähän tai napsauta valitaksesi tiedostoja</p>
      </div>
    </div>
  )
};